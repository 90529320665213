import { useSession, signIn } from "next-auth/react";
import { useEffect } from 'react';
import styled from 'styled-components'

const LoadingContainer = styled.div`
display:flex;
align-items:center;
justify-content:center;
height:80vh;
img{
    width:7em;
}
`

const OnlyAuthorized = ({ children }: { children: any }) => {

    const { data: session, status } = useSession()
    const loadingSession = status === "loading";

    const hasUser = !!session?.user;

    useEffect(() => {
        if (!loadingSession && !hasUser) {
            signIn()
        }
    }, [loadingSession, hasUser]);

    if (loadingSession || !hasUser) {
        return <>
            <LoadingContainer>
                <img src="/icons/loading.svg" />
            </LoadingContainer>
            <style jsx global>{`
        body{
            background: #f9fafd;
        }
        `}</style>
        </>
    }

    return children;
};

export default OnlyAuthorized;
import { SessionProvider } from "next-auth/react"
import dynamic from 'next/dynamic';
import { ErrorBoundary } from 'react-error-boundary'
import { SkeletonTheme } from 'react-loading-skeleton'
import { ToastContainer } from 'react-toastify';
import { ThemeProvider } from "styled-components";

import ClientError from "@components/layout/ClientError";
import OnlyAuthorized from "@components/layout/OnlyAuthorized";
import "react-toastify/dist/ReactToastify.css";
import 'react-loading-skeleton/dist/skeleton.css'
import '../styles/bundles.scss'

import { theme } from '../styles/theme'

const PageLoadingIndicator = dynamic(
  () => {
    return import("../components/layout/PageLoadingIndicator");
  },
  { ssr: false },
);


const Layout = dynamic(() => import('../components/layout/Layout'), { ssr: false });

export default function IcebreakerApp({
  Component,
  pageProps: { session, ...pageProps },
}: any) {
  return (
    <>
      <ErrorBoundary FallbackComponent={ClientError}>
        <ThemeProvider theme={theme}>
          <SkeletonTheme baseColor="#e7e9f1" highlightColor='#f6f7fb'>
            {Component.SignInPage ? <Component {...pageProps} /> : <>
              <SessionProvider session={session}>
                <OnlyAuthorized>
                  <Layout pageProps={pageProps}>
                    <PageLoadingIndicator />
                    <Component {...pageProps} />
                  </Layout>
                </OnlyAuthorized>
              </SessionProvider>
            </>}
            <ToastContainer />
          </SkeletonTheme>
        </ThemeProvider>
      </ErrorBoundary>
    </>
  )
}
import { DefaultTheme } from 'styled-components';

const theme: DefaultTheme = {
    colors: {
        main: '#00d1b2'
    },

    color: {
        main: '#283146',
        secondary: '#5f6b87'
    },
};

export { theme };